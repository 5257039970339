import { addDays, format, isPast, parseISO } from 'date-fns';
import { ReactElement, memo, useMemo, useRef, useCallback, useEffect, useState } from 'react';

import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { useMedia } from '@/common/service/hooks/useMedia';
import { Dimmer } from '@/common/ui/loaders/Dimmer/Dimmer';
import * as S from './CalendarHeader.styles';
import DayItem from './DayItem/DayItem';
import { fetchAvailabilityByMonth } from '@/common/service/api/Availability/Availability';
import { AvailabilityDatePicker } from '@/common/ui/inputs/AvailabilityDatePicker/AvailabilityDatePicker';
import { useDateContext } from '@/common/app/contexts';
import { getDateByTimezone } from '@/common/app/utils/dateUtils';
import { SERVER_FORMAT_DATE } from 'utils/constants';

const FIRST_LINE_ITEMS_COUNT = 3;

export const CalendarHeader = (): ReactElement => {
    const { activity, availability, selected, status, changeDay, changeWeek, chooseDate } =
        useActivityContext();
    const { date } = useDateContext();
    const currentDate = useMemo(
        () =>
            date || {
                from: format(new Date(), SERVER_FORMAT_DATE),
                to: format(addDays(new Date(), 6), SERVER_FORMAT_DATE),
            },
        [date]
    );
    const firstDay = availability.days_info?.[0]?.date || new Date().toISOString();
    const ref = useRef<HTMLDivElement>(null);
    const lastDay = availability.days_info?.[(availability.days_info?.length || 1) - 1]?.date || 0;
    const selectedMonthName = useMemo(
        () => format(getDateByTimezone(new Date(firstDay)), 'MMMM'),
        [firstDay]
    );
    const isMobileView = useMedia('(max-width: 991px)');
    const isPrevToday = isPast(getDateByTimezone(parseISO(firstDay)));

    const [calendarDate, setCalendarDate] = useState(
        `${new Date(currentDate.from).getFullYear()}-${new Date(currentDate.from).getMonth() + 1}`
    );
    const [isLoad, setIsLoad] = useState(false);
    const [notAvailableDays, setNotAvailableDays] = useState<number[]>([]);

    const fetchNotAvailable = useCallback(async () => {
        setIsLoad(true);
        const [result] = await Promise.all([
            fetchAvailabilityByMonth({
                activity_id: +selected.trip_id || +activity.id,
                month: calendarDate,
            }),
        ]);
        setNotAvailableDays(result);
        setIsLoad(false);
    }, [calendarDate, selected.trip_id, activity.id]);

    useEffect(() => {
        fetchNotAvailable();
    }, [fetchNotAvailable]);

    if (!availability.days_info || !+availability.use_calendar) {
        return <></>;
    }

    const firstLine = availability.days_info.slice(0, FIRST_LINE_ITEMS_COUNT);
    const secondLine = availability.days_info.slice(FIRST_LINE_ITEMS_COUNT);

    const activeElement = selected.calendar_id;

    const scrollToMonth = () => {
        isMobileView ? ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }) : null;
    };

    return (
        <Dimmer isLoading={status.isLoading}>
            <S.Container ref={ref} id="availability">
                <S.CalendarLine>
                    <S.PrevArrow onClick={() => changeWeek('prev')} disabled={isPrevToday}>
                        <S.LeftArrow disabled={isPrevToday} />
                        {isMobileView ? 'Prev week' : 'Previous Week'}
                    </S.PrevArrow>
                    <S.CalendarBtn onClick={() => scrollToMonth()}>
                        <AvailabilityDatePicker
                            label="Trip dates"
                            onChange={chooseDate}
                            dateStart={getDateByTimezone(new Date(firstDay))}
                            dateEnd={getDateByTimezone(new Date(lastDay))}
                            pastDisabled
                            notAvailableDays={notAvailableDays}
                            isNotSearch
                            setCalendarDate={setCalendarDate}
                            isLoad={isLoad}
                        >
                            <S.CalendarIcon />
                            {selectedMonthName}
                        </AvailabilityDatePicker>
                    </S.CalendarBtn>
                    <S.NextArrow onClick={() => changeWeek('next')} disabled={false}>
                        Next Week
                        <S.RightArrow />
                    </S.NextArrow>
                </S.CalendarLine>
                <S.DaysLine>
                    <S.FirstLine>
                        {firstLine.map((item) => (
                            <DayItem
                                isActive={activeElement === item.id}
                                isSoldout={!item.is_avail}
                                date={item.date}
                                minPrice={item.min_price}
                                key={item.id}
                                changeDay={changeDay(item.id)}
                            />
                        ))}
                    </S.FirstLine>
                    <S.SecondLine>
                        {secondLine.map((item) => (
                            <DayItem
                                isActive={activeElement === item.id}
                                isSoldout={!item.is_avail}
                                date={item.date}
                                minPrice={item.min_price}
                                key={item.id}
                                changeDay={changeDay(item.id)}
                            />
                        ))}
                    </S.SecondLine>
                </S.DaysLine>
            </S.Container>
        </Dimmer>
    );
};

export default memo(CalendarHeader);
