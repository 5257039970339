import { useMemo, useRef } from 'react';

import * as S from './FeaturedActivities.styles';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { FeaturedActivitiesSkeleton } from './FeaturedActivitiesSkeleton/FeaturedActivitiesSkeleton';
import { useAttractionsContext } from '@/common/app/contexts';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { shuffle } from '@/common/app/hooks/useRecommended';
import { AlsoViewedPreview } from './featuredActivitiesPreview/AlsoViewedPreview';

export type AlsoViewedProps = {
    item_list_name: string;
    item_list_id: string;
};

export const AlsoViewedBlock = ({ item_list_name, item_list_id }: AlsoViewedProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const { state, loading, finalList } = useAttractionsContext();

    const activities: IActivityWithAvailability[] = useMemo(() => {
        const availability = state.availability;
        return shuffle(
            finalList.map((i) => {
                if (availability[i.id]) {
                    return {
                        ...i,
                        price: availability[i.id].price,
                        price_subunits: availability[i.id].price_subunits,
                        price_strike_out: availability[i.id].price_strike_out || null,
                        price_strike_out_subunits:
                            availability[i.id].price_strike_out_subunits || null,
                        days_availability: availability[i.id].days_availability,
                    };
                }
                return i;
            })
        );
    }, [state.availability, finalList]);

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
    } = useCarousel({
        ref: scrollRef,
        countItems: activities.length || 4,
    });

    const skeletonObj = new Array(4).fill({ id: '' });
    if (!loading.initLoading && !activities.length) {
        return <></>;
    }

    return (
        <S.Wrapper>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {loading.initLoading ? (
                                    <>
                                        {skeletonObj.map((slide, index) => (
                                            <FeaturedActivitiesSkeleton key={slide.id || index} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {activities.map((slide, index) => (
                                            <AlsoViewedPreview
                                                activity={slide}
                                                position={index}
                                                key={slide.id}
                                                listName={item_list_name}
                                                item_list_id={item_list_id}
                                                item_list_name={item_list_name}
                                                promotion_name={item_list_name}
                                            />
                                        ))}
                                    </>
                                )}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && withDot && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && withDot && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};
