import { useState, memo, useEffect } from 'react';
import * as S from './FeaturedActivities.styles';
import { IRecommendsItem } from './featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';
import { useRecommended } from '@/common/app/hooks/useRecommended';
import { NewFeaturedActivities } from './NewFeaturedActivities';
import { AlsoViewedBlock } from './AlsoViewedBlock';

type Tabs = 'ALSO_VIEWED' | 'RECOMMENDS';

export type AlsoViewedActivitiesProps = {
    recommended?: IRecommendsItem[];
    alsoViewedActivities?: IActivityItem[];
    title: string;
    currentCategoryId: string;
};

export const AlsoViewedActivitiesContainer = ({
    title,
    recommended: defaultRecommended = [],
    alsoViewedActivities,
    currentCategoryId,
}: AlsoViewedActivitiesProps) => {
    const { recommended, loading } = useRecommended({
        defaultRecommended: defaultRecommended,
        currentCategoryId,
    });

    const [activeTab, setActiveTab] = useState<Tabs>('RECOMMENDS');

    useEffect(() => {
        if (!loading) {
            setActiveTab(recommended.length ? 'RECOMMENDS' : 'ALSO_VIEWED');
        }
    }, [loading, recommended.length]);

    if (!recommended?.length && !alsoViewedActivities?.length) return null;

    const item_list_id = typeof window !== 'undefined' ? window.location.pathname : '';

    const onTabClick = (tab: Tabs) => {
        setActiveTab(tab);
    };

    return (
        <S.AlsoViewedWrapper>
            <S.Block data-test-id="Widget-attraction" className="container">
                <S.AlsoViewedHeader>
                    <S.LeftPartHeader>
                        <S.TabsWrapper>
                            {!!recommended?.length && (
                                <>
                                    <S.Tab
                                        isActive={activeTab === 'RECOMMENDS'}
                                        onClick={() => onTabClick('RECOMMENDS')}
                                    >
                                        <span>{title}</span>
                                    </S.Tab>
                                    <S.TabDivider />
                                </>
                            )}
                            <>
                                <S.Tab
                                    isActive={activeTab === 'ALSO_VIEWED'}
                                    onClick={() => onTabClick('ALSO_VIEWED')}
                                >
                                    <span>Travelers Also Viewed</span>
                                </S.Tab>
                            </>
                        </S.TabsWrapper>
                    </S.LeftPartHeader>
                </S.AlsoViewedHeader>
                {activeTab === 'RECOMMENDS' && (
                    <S.RecommendsBlock>
                        <NewFeaturedActivities
                            defaultActivities={defaultRecommended}
                            title=""
                            item_list_id={item_list_id}
                            item_list_name={title}
                            placeholders={{}}
                            withoutDots
                            currentCategoryId={currentCategoryId}
                        />
                    </S.RecommendsBlock>
                )}
                {activeTab === 'ALSO_VIEWED' && (
                    <S.RecommendsBlock>
                        <AlsoViewedBlock
                            item_list_id={item_list_id}
                            item_list_name={'Travelers Also Viewed'}
                        />
                    </S.RecommendsBlock>
                )}
            </S.Block>
        </S.AlsoViewedWrapper>
    );
};

export const AlsoViewedActivities = memo(AlsoViewedActivitiesContainer);
