import styled from 'styled-components';

import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { FlexPresets } from '@/styles/Themes/Presets';

export const Container = styled.div<{ isGiftCard: boolean }>`
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${({ isGiftCard }) => (isGiftCard ? `${radius.small}` : '0 0 15px 15px')};
    padding: 15px 20px 50px;
    position: relative;
    overflow: hidden;

    ${Screen.ipad} {
        padding: 20px 30px 60px;
    }
    ${Screen.desktop} {
        padding: 19px 41px 55px;
    }
`;

export const Title = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.medium}
    padding-bottom: 15px;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};
    display: flex;
    justify-content: space-between;

    ${Screen.ipad} {
        padding-bottom: 20px;
    }
`;

export const Status = styled.div<{ isRed: boolean }>`
    ${FlexPresets.flexRowCenter};
    width: 106px;
    height: 24px;
    border-radius: ${radius.large};
    color: ${Palette.white.normal};
    ${Text.Full.medium.semibold}
    background-color: ${({ isRed }) => (isRed ? Palette.state.error : Palette.green.normal)};
    text-align: center;
    padding-top: 1px;

    ${Screen.ipad} {
        width: 158px;
    }
    ${Screen.desktop} {
        width: 140px;
    }
`;

export const Subtitle = styled.span`
    display: block;
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    padding: 22px 0 15px;

    ${Screen.desktop} {
        padding: 24px 0 20px;
    }
`;

export const TicketsGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    ${Screen.ipad} {
        grid-template-columns: 1fr 1fr;
    }

    ${Screen.desktop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const OptionGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: flex-start;

    ${Screen.desktop} {
        grid-template-columns: 1fr 1fr;
        padding-left: 30px;
    }
`;

export const PolicyGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    padding: 0 16px;
    margin-bottom: 32px;

    ${Screen.ipad} {
        padding: 0 19px;
    }

    ${Screen.desktop} {
        padding-left: 30px;
    }
`;

export const OptionText = styled.span`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    display: flex;
    margin: 0 0 4px 18px;
`;

export const Required = styled.span`
    color: ${Palette.state.error};
    margin-right: 8px;
`;

export const TextArea = styled.textarea`
    width: 90%;
    height: 80px;
    resize: none;
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.small};
    padding: 22px 25px;
    margin: 0 16px;
    background-color: inherit;
    color: ${cssVars.fg_gray1_white};
    font-family: inherit;

    &::placeholder {
        color: ${Palette.gray.gray3};
        opacity: 0.7;
        ${Text.Full.medium.medium}
    }

    &:focus {
        outline: 2px solid ${Palette.green.normal};
    }

    ${Screen.ipad} {
        margin: 0 19px;
        width: 94%;
    }

    ${Screen.desktop} {
        margin: 0 25px;
    }
`;

export const BtnWrapper = styled.div`
    padding: 15px 0 30px;

    ${Screen.ipad} {
        margin: 0 19px;
    }

    ${Screen.desktop} {
        padding: 0;
        position: absolute;
        bottom: 56px;
        right: 23px;
        width: 250px;
    }
`;

export const TripflexOver = styled.div`
    color: ${Palette.state.error};
    ${Text.Full.normal.medium}
    text-decoration: unset;
    margin-top: 4px;
`;

export const PolicyText = styled.div`
    color: ${Palette.state.info};
    ${Text.Full.medium.medium}
    cursor: pointer;
    text-decoration: underline;
`;

export const TipTitle = styled.p`
    ${Text.Full.medium.medium}
`;

export const TipText = styled.p`
    ${Text.Full.normal.medium}
`;

export const BlueText = styled.span`
    color: #2f96fd;
`;

export const FirstStepWrapper = styled.span`
    display: flex;
    flex-direction: column;
`;
