import { memo } from 'react';
import * as S from '../ActivityPage.styles';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { NewFeaturedActivities } from '@/shared/FeaturedActivities/ui/NewFeaturedActivities';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';

const ActivityFeaturedActivitiesContainer = ({
    title,
    isGiftCard,
    currentActivity,
    recommends,
}: {
    title: string;
    isGiftCard: boolean;
    currentActivity: IActivity;
    recommends: IRecommendsItem[];
}) => {
    if (!recommends.length) {
        return null;
    }

    const item_list_id = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <S.WrapperWithMargin isGiftCard={isGiftCard}>
            <NewFeaturedActivities
                defaultActivities={recommends.filter((i) => i.id !== currentActivity.id)}
                title={title}
                item_list_id={item_list_id}
                item_list_name={title}
                placeholders={{}}
            />
        </S.WrapperWithMargin>
    );
};

export const ActivityFeaturedActivities = memo(ActivityFeaturedActivitiesContainer);
