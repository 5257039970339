import { Rating } from '@/entities/Activity/ui/Rating';
import { numberFormatPrice, numberWithCommaFormatter } from '@/common/app/utils/numberUtils';
import URL from '@/common/app/utils/urlsUtils';
import { Gradient } from '@/screens/CommonPage/ui/commonPage/CardItem/cardItem/CardImage/CardImage.styles';
import * as S from './NewFeaturedActivitiesPreview.styles';
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import {
    convertActivityDataToAnalyticsData,
    createListNameFromPath,
    wraitDetasilsData,
} from '@/shared/Analytics/GA4/helpers';
import { promotionInterceptor } from '@/shared/Analytics/GA4/promotionInterceptor';
import { trackSelectPromotion } from '@/shared/Analytics/GA4';
import { myImageLoader } from '@/lib/imageLoader';
import { defaultPlaceholder } from '@/shared/images/preloader/defaultPlaceholder';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';

interface IAlsoViewedProps {
    activity: IActivityWithAvailability;
    position: number;
    listName: string;
    item_list_name: string;
    item_list_id: string;
    promotion_name: string;
}

export const AlsoViewedPreview = ({
    activity,
    position,
    item_list_id,
    item_list_name,
    promotion_name,
}: IAlsoViewedProps) => {
    const {
        duration,
        price,
        url,
        id,
        rating_stars,
        name,
        price_strike_out,
        rate_count,
        reviews,
        placeholder,
    } = activity;

    const reviewsCount = Number(rate_count || '0');
    const reviewsCountWithComma = numberWithCommaFormatter(reviewsCount);
    const priceWithComma = numberFormatPrice(price);
    const priceStrikeOutWithComma =
        price_strike_out && price_strike_out > price ? numberFormatPrice(price_strike_out) : '';

    const [ref] = useInView({
        threshold: 1,
        delay: 100,
        trackVisibility: true,
        onChange: (inView) => {
            if (inView) {
                promotionInterceptor.addPromotion(
                    convertActivityDataToAnalyticsData(
                        activity,
                        position,
                        item_list_name,
                        item_list_id
                    ),
                    promotion_name
                );
            }
        },
    });

    const clickHandler = () => {
        wraitDetasilsData({
            index: position + 1,
            item_list_name,
            item_list_id,
            promotion_name: promotion_name || '',
            item_category3: createListNameFromPath(),
        });
        trackSelectPromotion({
            creative_name: createListNameFromPath(),
            promotion_name: promotion_name || '',
            items: [
                {
                    ...convertActivityDataToAnalyticsData(
                        activity,
                        position,
                        item_list_name,
                        item_list_id
                    ),
                },
            ],
        });
    };

    return (
        <Link href={url.toLowerCase()} passHref prefetch={false}>
            <S.CardWidth ref={ref} className="card" onClick={clickHandler}>
                <S.PreviewWrapper>
                    <S.ImageContainer>
                        <S.ImgWrap>
                            <Gradient />
                            <S.ImageComponent
                                loader={myImageLoader}
                                src={`${URL.image}/activity/${id}/center/480x320/ts`}
                                width={240}
                                height={160}
                                alt={name}
                                blurDataURL={placeholder || defaultPlaceholder}
                                placeholder="blur"
                            />
                        </S.ImgWrap>
                        <S.PriceWrapper>
                            <>
                                {!!priceStrikeOutWithComma && (
                                    <S.StrikeOut>{priceStrikeOutWithComma}</S.StrikeOut>
                                )}
                                &nbsp;
                                <S.Price>{priceWithComma}</S.Price>
                            </>
                        </S.PriceWrapper>
                    </S.ImageContainer>
                    <S.ContentContainer>
                        <S.Title>{name}</S.Title>
                        <S.DurationWrapper>
                            {!!reviews || !!rate_count ? (
                                <>
                                    <Rating rating={rating_stars} />
                                    {reviewsCountWithComma !== '0' && (
                                        <S.RatingText>({reviewsCountWithComma})</S.RatingText>
                                    )}
                                </>
                            ) : (
                                <S.NoReviews>Newly added</S.NoReviews>
                            )}
                            {!reviewsCount && <S.NoReviews>Newly added</S.NoReviews>}

                            <S.Time>
                                {duration !== '' && (
                                    <>
                                        <S.ClockIcon />
                                        <S.Minutes>{duration}</S.Minutes>
                                    </>
                                )}
                            </S.Time>
                        </S.DurationWrapper>
                    </S.ContentContainer>
                </S.PreviewWrapper>
            </S.CardWidth>
        </Link>
    );
};
